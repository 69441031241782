<template>
    <div class="UserAnaly_A">
        <div class="rana_con">
            <div class="rana_con_title">
                筛选最终确定的目标客户群体
            </div>
            <!-- 内容 -->
            <div class="rana_con_content">
                <div class="arrow_box">
                    <div class="arrow_box_left">
                        <!-- <img src="./../../../assets/img/arrow_left.png" alt=""> -->
                    </div>
                    <ul class="arrow_box_cente">
                        <li v-for="(item,index) in caseList" :key="index" class="li_up">
                            <div class="arr_item_img">
                                <img v-if="item.val==1" src="./../../../assets/img/icon_white.png" alt="">
                                <img v-else src="./../../../assets/img/icon_blue.png" alt="">
                            </div>
                            <div class="tiem_one" style="margin-top:10px">
                                <span class="_one_span">目标客户</span><span>：{{item.name}}</span>
                            </div>
                            <div class="tiem_one">
                                <span class="_one_span">主语</span><span>：{{item.role}}</span>
                            </div>
                            <div class="tiem_one_gm">
                                <span class="_one_span">购买动机</span><span>：{{item.gmdj}}</span>
                            </div>
                            <div class="tiem_one_gm">
                                <span class="_one_span">购买行为</span><span>：{{item.gmxw}}</span>
                            </div>
                        </li>
                    </ul>
                    <div class="arrow_box_left">
                        <!-- <img src="./../../../assets/img/arrow_right.png" alt=""> -->
                    </div>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="butt_div">
                <div class="button_one" v-preventReClick @click="nextBotn()">
                    <span>返回首页</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            istrue:0,
            caseList:[]
        };
    },
    methods: {
        getapp(){
            this.axios.projectS2P3({
                token:this.$store.state.token,
                id:this.$store.state.cla_id
            }).then(res=>{
                if(res.data.code==0){
                    var str = res.data.data.qrcode
                    this.$emit('info', str);
                    this.caseList = res.data.data.values.filter(el=> el.val==1)
                }else{
                     this.caseList = [] 
                }
            }).catch(err=>{

            })
        },
        nextBotn(){
            this.$router.push({name:'StudentPage'})
        }
    },
    mounted() {
        this.getapp()
    }
};
</script>
<style scoped>
@import './../../../assets/css/student/A/UserAnaly_A.css';
</style>